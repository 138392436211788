import React, { useState, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { InteractionConfig } from './types';

export default function Interaction(props: {
	shouldPlay: boolean;
	config: InteractionConfig;
	onLoaded: () => void;
	onDone: () => void;
	onError: (e: Error) => void;
}) {
	const { config, onLoaded, onDone, shouldPlay, onError } = props;
	const [imageLoaded, setImageLoaded] = useState(false);
	const [animationComplete, setAnimationComplete] = useState(false);
	const [loaded, setLoaded] = useState(false); // pre loading state
	const soundRef = useRef<HTMLAudioElement>(null);
	const imageRef = useRef<HTMLImageElement>(null);
	const textRef = useRef<HTMLSpanElement>(null);


	useEffect(() => {
		if (imageLoaded) {
			onLoaded();
			setTimeout(() => {
				onDone();
				if (soundRef.current) {
					soundRef.current.pause();
				}
			}, config.timeout || 25_000);
		}
	}, [imageLoaded, config.timeout, onLoaded, onDone]);

	useEffect(() => {
		if (!shouldPlay || !imageLoaded) return;
		if (soundRef.current) {
		
			soundRef.current.volume = config?.volume ?? 0.7;
			soundRef.current.play().catch(error => onError(new Error('Audio playback failed')));
		}
	}, [shouldPlay, imageLoaded, config?.volume, onError]);

	useEffect(() => {
		if (soundRef.current) {
			setLoaded(true);
		} else {
			window.parent.postMessage({ status: 'error', error: `failed to load audio ${soundRef.current}` }, '*');
		}
	}, [config]);


	useEffect(() => {
		if (textRef.current) {
			adjustFontSize();
			window.addEventListener('resize', adjustFontSize);
			return () => window.removeEventListener('resize', adjustFontSize);
		}
	}, [imageLoaded]);

	const adjustFontSize = () => {
		const textElement = textRef.current;
		const container = textElement?.parentElement;
		if (!textElement || !container) return;

		let fontSize = 15; // Start with 10vw or a reasonable size
		textElement.style.fontSize = `${fontSize}vw`;

		while (textElement.scrollWidth > container.clientWidth || textElement.scrollHeight > container.clientHeight) {
			fontSize -= 1;
			textElement.style.fontSize = `${fontSize}vw`;
			if (fontSize <= 1) break; // Prevent font size from becoming too small
		}
	};

	const handleImageLoad = () => setImageLoaded(true);

	return (
		<AnimatePresence>
			<div className="relative w-max h-max">
				<audio
					src="/soundeffect.mp3"
					ref={soundRef}
				/>
				{loaded &&
					<motion.div
						className="flex justify-center items-center h-screen"
						initial={{ opacity: 0, scale: 0.5 }}
						exit={{ opacity: 0, scale: 1 }}
						animate={{ opacity: imageLoaded ? 1 : 0, scale: imageLoaded ? 1 : 0.5 }}
						transition={{ duration: 0.6, delay: 0 }}
						onAnimationComplete={() => setAnimationComplete(true)}
					>
						<div>
							<img
								alt="hat"
								src="./bluehat2.png"
								ref={imageRef}
								onLoad={handleImageLoad}
								className={`w-full h-auto max-w-[800px] max-h-[800px] ${config.mirrored ? 'scale-x-[-1]' : ''}`}
								style={{ visibility: imageLoaded ? 'visible' : 'hidden' }}
							/>
							{imageLoaded && (
								<div className='absolute inset-0 flex items-center justify-center mb-44'>
									<div className="font-bold text-white text-center w-[70%] h-[50%] flex items-center justify-center overflow-hidden">
										<span
											ref={textRef}
											className="responsive-text leading-tight break-words"
											style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
											{config.msg}
										</span>
									</div>
								</div>
							)}
						</div>
					</motion.div>
				}
				{animationComplete && imageLoaded && (
					<video src="/sparkle.webm" autoPlay className="absolute inset-0 w-full h-full object-cover" />
				)}
			</div>
		</AnimatePresence>
	);
}
